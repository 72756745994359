import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Aheader from "../Aheader/Aheader";
import { Sidebar } from "./Sidebar";

function User() {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const handleClick = () => {
    navigate("/useradd");
  };
  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/user/allUser")
      .then((res) => {
        setUsers(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleDelete = (id) => {
    axios
      .delete("https://backend.akptc.et/api/user/deluser/" + id)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    window.location.reload();
  };
  return (
    <div>
      <Aheader />
      <div className="flex ">
        <Sidebar />
        <div className="ml-60 mt-20 w-full">
          <div className="flex justify-end my-4 py-2 pr-4">
            <button
              type=""
              className="uppercase bg-black text-white py-2 px-2"
              onClick={handleClick}
            >
              +add user
            </button>
          </div>
          <div className="font-bold font-serif underline uppercase mb-4  text-500 flex justify-center">
            <h2>User List</h2>
          </div>
          <div className="px-2 ">
            <table className="border-collapse border border-slate-500 w-full  ">
              <thead className="font-bold bg-gray-300">
                <tr>
                  <td className="border border-slate-700 font-serif">#</td>
                  <td className="border border-slate-700 font-serif">Role</td>
                  <td className="border border-slate-700 font-serif">
                    Full Name
                  </td>
                  <td className="border border-slate-700 font-serif">
                    Employee ID
                  </td>
                  <td className="border border-slate-700 font-serif">
                    Admin Name
                  </td>
                  <td className="border border-slate-700 font-serif">Action</td>
                </tr>
              </thead>
              <tbody>
                {users.map((data, index) => (
                  <tr key={index}>
                    <td className="border border-slate-600">{index}</td>
                    <td className="border border-slate-600">
                      {data.user_role}
                    </td>
                    <td className="border border-slate-600">
                      {data.user_name}
                    </td>
                    <td className="border border-slate-600">{data.user_id}</td>
                    <td className="border border-slate-600">
                      {data.admin_name}
                    </td>

                    <td className="border border-slate-600">
                      <button
                        onClick={(e) => handleDelete(data.id)}
                        className="font-serif bg-black text-white my-2 ml-4 px-4 border rounded rounded-lg"
                      >
                        Delete user
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
