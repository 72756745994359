import axios from "axios";
import logo from "./../../Images/312171893_500382585442367_742616998542970294_n.jpg";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContex } from "../../context API/UserContext";
import "./Login.css";
function Login() {
  const [userData, setUserData] = useContext(UserContex);
  const [Password, setPassword] = useState("");
  const [employeeID, setID] = useState("");
  let [role, setRole] = useState("");
  const navigate = useNavigate();
  const handlesubmit = async (e) => {
    e.preventDefault();
    // if ((role = "Admin")) {
    //   navigate("/Admin");
    // } else {
    //   navigate("/registration");
    // }

    axios
      .post("https://backend.akptc.et/api/user/login", {
        role,
        employeeID,
        Password,
      })
      .then((res) => {
        console.log(res.data.user);
        setUserData({
          token: res.data.token,
          user: res.data.user,
        });

        localStorage.setItem("auth-token", res.data.token);
        if (res.data.user.role === "Registration") {
          navigate("/registration");
        }
        if (res.data.user.role === "Admin") {
          navigate("/Admin");
        }
      })
      .catch((err) => alert("please try again"));
  };

  return (
    <div className=" ">
      <div className="bg-gray-400 flex flex-col justify-center items-center h-screen  ">
        <div className="flex mb-4 items-center">
          <img
            src={logo}
            alt=""
            className="h-32 w-32 border rounded-full mr-4"
          />
          <h2 className="font-bold font-serif italic text-4xl">
            Athlete Kenenisa Bekele Polytechnic College
          </h2>
        </div>
        <div className="w-3/4 h-2/3 md:w-1/2 md:h-2/3 bg-white  border border-black-1 border-rounded rounded-2xl p-4 shadow-lg shadow-white-500/50 ">
          <div className="flex justify-center mt-2 mb-4">
            <h1 className="uppercase font-bold text-2xl">Login</h1>
          </div>
          <form onSubmit={handlesubmit}>
            <label className="font-bold mt-4">Role</label>

            <select
              name="userRole"
              id="userRole"
              onChange={(e) => setRole(e.target.value)}
              className="w-full h-12 md:h-8 pl-2 mb-4 border border-gray-300 rounded-2xl"
            >
              <option selected>Choose Role</option>
              <option value="Admin">Admin</option>
              <option value="Registration">Registration</option>
            </select>
            <label className="mt-4 font-bold">Empoyee ID</label>
            <input
              placeholder="Employee ID"
              type="text"
              className="w-full  pl-2 h-12 md:h-8 mb-4 border border-gray-300 rounded-2xl"
              onChange={(e) => setID(e.target.value)}
            />

            <label className="mt-4 font-bold">Password</label>
            <input
              placeholder="Password"
              type="password"
              className="w-full h-12 md:h-8 pl-2 border border-gray-300 rounded-2xl"
              onChange={(e) => setPassword(e.target.value)}
            />

            <div className="flex justify-center mt-4">
              <button
                type=""
                className="uppercase bg-red-500 mt-4 text-white px-4 py-1 rounded rounded-fully"
              >
                login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
