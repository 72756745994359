import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Aheader from "../Aheader/Aheader";
import { Sidebar } from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { UserContex } from "../../context API/UserContext";
function Adminhome({ logout }) {
  const [male, setMale] = useState([]);
  const [female, setFemale] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [year, setYear] = useState("");
  const [department, setDepartment] = useState("");
  const [userData, setUserData] = useContext(UserContex);
  // console.log(userData?.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (!userData.user) {
      navigate("/");
    }
  }, [userData.user, navigate]);

  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/dep/getDep")
      .then((res) => {
        setDepartments(res.data.data);
        console.log(departments);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(male);
  console.log(female);
  let yers = [];
  for (let y = 2008; y <= 2050; y++) {
    yers.push(y);
    console.log(y);
  }
  // console.log(yers);
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://backend.akptc.et/api/student/maleStudent", {
        year,
        department,
      })
      .then((res) => {
        console.log(res.data.data);
        setMale(res.data.data);
      });
    axios
      .post("https://backend.akptc.et/api/student/femaleStudent", {
        year,
        department,
      })
      .then((res) => {
        console.log(res.data.data);
        setFemale(res.data.data);
      });
  };

  console.log(department);
  return (
    <div>
      <Aheader logout={logout} />
      <div className="flex ">
        <Sidebar />
        <div className="ml-52 bg-white text-black  w-full pt-32">
          <div className="mb-40 ml-20 flex justify-between items-center">
            <div>
              <form action="" onClick={handleSubmit} className="flex">
                <select
                  onChange={(e) => setYear(e.target.value)}
                  id="countries"
                  className="mr-2 bg-black border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "
                >
                  <option selected>Choose year</option>
                  {yers.map((data, index) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
                <select
                  onChange={(e) => setDepartment(e.target.value)}
                  id="countries"
                  className="mr-2 bg-black border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "
                >
                  <option selected>Department</option>
                  {departments.map((data, index) => (
                    <option value={data.id}>{data.dep_name}</option>
                  ))}
                </select>
              </form>
            </div>

            <div className="grid grid-cols-3 gap-4 mr-4 font-bold">
              <h1>Total Female:{female.length}</h1>
              <h1>Total Male:{male.length}</h1>
              <h1>Total Student:{female.length + male.length}</h1>
            </div>
            <Link
              to={`/changepassword`}
              className="bg-black border border-gray-300 text-gray-900 text-white rounded-lg block  p-2.5  font-bold hover:text-black hover:bg-white hover:border-black"
            >
              Change Password
            </Link>
          </div>
          <div className="flex flex-col justify-center items-center">
            <h1 className="font-bold text-4xl lg:text-6xl uppercase">
              <marquee behavior="" direction="">
                well come to Admin
              </marquee>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminhome;
