import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import "./Sidebar.css";
export function Sidebar() {
  return (
    <Card className="h-[calc(100vh-2rem)] w-full max-w-[15rem] gap-12 bgimg mt-20 fixed">
      <div className="bg-black opacity-80 p-4  text-white h-screen w-full ">
        <div className=" mb-2 p-4 uppercase font-bold">
          <Typography variant="h5" color="blue-gray">
            Admin
          </Typography>
        </div>
        <List className="block gap-8 font-bold ">
          <Link
            to={"/Admin"}
            className="gap-4 hover:text-orange active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 "
          >
            <ListItem>
              <ListItemPrefix>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                </svg>
              </ListItemPrefix>
              Home
            </ListItem>
          </Link>
          <Link
            to={"/user"}
            className="gap-4 hover:text-orange active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 "
          >
            <ListItem>
              <ListItemPrefix>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-5-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM8 9c-1.825 0-3.422.977-4.295 2.437A5.49 5.49 0 0 0 8 13.5a5.49 5.49 0 0 0 4.294-2.063A4.997 4.997 0 0 0 8 9Z"
                    clipRule="evenodd"
                  />
                </svg>
              </ListItemPrefix>
              Users
            </ListItem>
          </Link>
          <Link
            to={"/department"}
            className="gap-4 hover:text-yellow active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300"
          >
            <ListItem className="pl-8 py-1">Occupation</ListItem>
          </Link>
          <Link
            to={"/criteria"}
            className="gap-4 hover:text-yellow active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 "
          >
            <ListItem className="pl-8 py-1">Manage Registration</ListItem>
          </Link>
          <Link
            to={"/course"}
            className="gap-4 hover:text-yellow active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 "
          >
            <ListItem className="pl-8 py-1">Manage Course</ListItem>
          </Link>
          <Link
            to={"/managerecord"}
            className="gap-4 hover:text-yellow active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 "
          >
            <ListItem className="pl-8 py-1">Manage Record</ListItem>
          </Link>
        </List>
      </div>
    </Card>
  );
}
