import React, { createContext, useState } from "react";
export const UserContex = createContext();
export const UserProvider = (props) => {
  const [userData, setUserData] = useState({
    user: undefined,

    token: undefined,
  });
  return (
    <UserContex.Provider value={[userData, setUserData]}>
      {props.children}
    </UserContex.Provider>
  );
};
