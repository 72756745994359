import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Aheader from "../Aheader/Aheader";
import { Sidebar } from "./Sidebar";

const Courses = () => {
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState("");
  const [course, setCourse] = useState("");
  const [level, setLevel] = useState("");
  const [nominal, setNominal] = useState("");
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/dep/getDep")
      .then((res) => {
        setDepartments(res.data.data);
        console.log(departments);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://backend.akptc.et/api/course/setcourse", {
        department,
        course,
        level,
        nominal,
      })
      .then((res) => {
        console.log(res.data);
        alert(res.data.msg);
        // navigate("/admin");

        window.location.reload();
      })
      .catch((err) => {
        alert(err.response.data.msg);
        console.log(err);
      });
  };
  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/course/courses")
      .then((res) => {
        setCourses(res.data.data);
        console.log(courses);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(level);
  return (
    <div className=" ">
      <Aheader />
      <div className="flex">
        <Sidebar />
        <div className="bg-gray-400 h-screen pt-12 ml-60 mt-20 w-full">
          <div className="text-gray-800 flex justify-center mt-2 mb-4">
            <h1 className="uppercase font-bold text-2xl underline">
              Unit of Competency
            </h1>
          </div>
          <div className="grid grid-cols-2 ">
            <div>
              <div className="px-2 ">
                <table className="border-collapse border border-slate-500 w-full bg-white ">
                  <thead className="font-bold bg-gray-300">
                    <tr>
                      <td className="border border-slate-700 font-serif">#</td>
                      <td className="border border-slate-700 font-serif">UC</td>
                      <td className="border border-slate-700 font-serif">
                        Occupation
                      </td>
                      <td className="border border-slate-700 font-serif">
                        Level
                      </td>
                      <td className="border border-slate-700 font-serif">
                        Nominal Hour
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {courses.map((data, index) => (
                      <tr>
                        <td className="border border-slate-600">{index}</td>
                        <td className="border border-slate-600">
                          {data.course_name}
                        </td>
                        <td className="border border-slate-600">
                          {data.dep_name}
                        </td>
                        <td className="border border-slate-600">
                          {data.level}
                        </td>
                        <td className="border border-slate-600">
                          {data.nominal_hour}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-white  border border-black-1 border-rounded rounded-2xl p-4 h-64 ml-4 mr-2">
              <form className="" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2">
                  <div className="mr-4 mb-2">
                    <label className="mt-4 font-bold">Level</label>
                    <select
                      id="levellist"
                      onChange={(e) => setLevel(e.target.value)}
                      className="w-full  pl-2 h-12 md:h-8 mb-2 border border-gray-300 rounded-2xl"
                    >
                      <option selected>Choose Level</option>
                      <option value="I">I</option>
                      <option value="II">II</option>
                      <option value="III">III</option>
                      <option value="IV">IV</option>
                      <option value="V">V</option>
                    </select>
                  </div>
                  <div className=" mb-2">
                    <label className="font-bold">Occupation</label>
                    <select
                      id="deplist"
                      onChange={(e) => setDepartment(e.target.value)}
                      placeholder="Department"
                      className="w-full  pl-2 h-12 md:h-8 mb-2 border border-gray-300 rounded-2xl"
                    >
                      <option selected>Choose Occupation</option>
                      {departments.map((data, index) => (
                        <option value={data.id}>{data.dep_name}</option>
                      ))}
                    </select>
                  </div>
                  <div className=" mr-4 mb-4">
                    <label className="font-bold">UC</label>
                    <input
                      placeholder="Course"
                      type="text"
                      onChange={(e) => setCourse(e.target.value)}
                      className="w-full mr-4 pl-2 h-12 md:h-8 mb-2 border border-gray-300 rounded-2xl"
                    />
                  </div>
                  <div className=" mr-4 mb-4">
                    <label className="font-bold">nominal hour</label>
                    <input
                      placeholder="Course"
                      type="number"
                      onChange={(e) => setNominal(e.target.value)}
                      className="w-full mr-4 pl-2 h-12 md:h-8 mb-2 border border-gray-300 rounded-2xl"
                    />
                  </div>
                </div>

                <div className="flex justify-center mt-2">
                  <button
                    type=""
                    className="uppercase bg-red-500 mt-4 text-white px-4 py-1 rounded rounded-fully"
                  >
                    Submit
                  </button>
                </div>
              </form>
              {/* <div>{response && <p>{response}</p>}</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
