import { PowerIcon } from "@heroicons/react/24/solid";
import { ListItem, ListItemPrefix } from "@material-tailwind/react";
import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContex } from "../../context API/UserContext";
function Aheader({ logout }) {
  const [userData, setUserData] = useContext(UserContex);
  const navigate = useNavigate();

  return (
    <div className="flex justify-between px-4 pt-6 bg-black  h-20 w-full fixed ">
      <div>
        <h1 className="uppercase text-2xl  font-bold text-white font-serif italic">
          athlete kenenisa Bekele polythecnic college
        </h1>
      </div>
      <div className="flex">
        <h1 className="mr-2 text-white">{userData.user?.name}</h1>
        <Link to="">
          <ListItemPrefix>
            <button type="" onClick={logout}>
              <PowerIcon className="h-6 w-6 font-xbold cursor-pointer text-white" />
            </button>
          </ListItemPrefix>
        </Link>
      </div>
    </div>
  );
}

export default Aheader;
