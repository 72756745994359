import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContex } from "../../context API/UserContext";

const ChangePassword = () => {
  const [userData, setUserData] = useContext(UserContex);
  const [userId, setUserId] = useState(userData.user?.uid);
  const navigate = useNavigate();
  //   console.log(userId);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const handlesubmit = (e) => {
    e.preventDefault();
    axios
      .put("https://backend.akptc.et/api/user/update", {
        userId,
        newPassword,
        oldPassword,
      })
      .then((res) => {
        console.log(res);
        alert(res.data.msg);
        navigate("/");
        // if (userData.user?.uid === "Admin") {
        //   navigate("/admin");
        // }
        // if (userData.user?.uid === "Registration") {
        //   navigate("/registration");
        // }
      })
      .catch((err) => alert(err.response.data.msg));
  };
  return (
    <div className=" ">
      <div className="bg-gray-400  flex justify-center items-center h-screen pt-12 ">
        <div className="w-3/4 h-3/4 md:w-1/2 md:h-3/4 bg-white  border border-black-1 border-rounded rounded-2xl p-4 shadow-lg shadow-white-500/50 ">
          <div className="flex justify-center mt-2 mb-4">
            <h1 className="uppercase font-bold text-2xl">Change Password</h1>
          </div>
          <form onSubmit={handlesubmit}>
            <label className="mt-4 font-bold">Old Password</label>
            <input
              placeholder="Old Password"
              type="text"
              className="w-full  pl-2 h-12 md:h-8 mb-4 border border-gray-300 rounded-2xl"
              onChange={(e) => setOldPassword(e.target.value)}
            />

            <label className="mt-4 font-bold">New Password</label>
            <input
              placeholder="New Password"
              type="password"
              className="w-full h-12 md:h-8 pl-2 border border-gray-300 rounded-2xl"
              onChange={(e) => setNewPassword(e.target.value)}
            />

            <div className="flex justify-center mt-4">
              <button
                type=""
                className="uppercase bg-red-500 mt-4 text-white px-4 py-1 rounded rounded-fully"
              >
                Change
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
