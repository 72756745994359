import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContex } from "../../context API/UserContext";

const StudentRegistration = () => {
  const [userData, setUserData] = useContext(UserContex);
  const [userName, setUserName] = useState(userData.user?.name);
  // setUserName(userData?.user.name);
  console.log(userName);
  const [firstName, setFirstname] = useState("");
  const [middleName, setMiddlename] = useState("");
  const [lastName, setLastname] = useState("");
  const [birthPlace, setBirthplace] = useState("");
  const [sex, setSex] = useState("");
  const [age, setAge] = useState("");
  const [grade12th, set12thTaken] = useState("");
  const [nationalScore, setNationalscore] = useState("");
  const [referee, setReferee] = useState("");
  const [transcript9, setTranscript9] = useState("");
  const [transcript10, setTranscript10] = useState("");
  const [transcript11, setTranscript11] = useState("");
  const [transcript12, setTranscript12] = useState("");
  const [department, setDepartment] = useState("");
  const [level, setLevel] = useState("");
  const [regDate, setRegDate] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedFile1, setSelectedFile1] = useState();
  const [selectedFile2, setSelectedFile2] = useState();
  const [rzone, setrZone] = useState("");
  const [rwereda, setrWereda] = useState("");
  const [rkebele, setrKebele] = useState("");
  const [rphone, setrPhone] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/dep/getDep")
      .then((res) => {
        setDepartments(res.data.data);
        // console.log(departments);
      })
      .catch((err) => console.log(err));
  }, []);

  const handlesubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file1", selectedFile1);
    formData.append("file2", selectedFile2);
    formData.append("firstName", firstName);
    formData.append("middleName", middleName);
    formData.append("lastName", lastName);
    formData.append("referee", referee);
    formData.append("birthPlace", birthPlace);
    formData.append("sex", sex);
    formData.append("age", age);
    formData.append("grade12th", grade12th);
    formData.append("nationalScore", nationalScore);
    formData.append("transcript9", transcript9);
    formData.append("transcript10", transcript10);
    formData.append("transcript11", transcript11);
    formData.append("transcript12", transcript12);
    formData.append("level", level);
    formData.append("regDate", regDate);
    formData.append("department", department);
    formData.append("userName", userName);
    formData.append("rzone", rzone);
    formData.append("rwereda", rwereda);
    formData.append("rkebele", rkebele);
    formData.append("rphone", rphone);
    axios
      .post("https://backend.akptc.et/api/student/sRegistration", formData)
      .then((res) => {
        alert(res.data.msg);
        navigate("/student");
      })
      .catch((err) => alert(err.response.data.msg));
  };

  let yers = [];
  for (let y = 2008; y <= 2050; y++) {
    yers.push(y);
    console.log(y);
  }
  return (
    <div className="bg-gray-400  pb-24">
      <div className="flex justify-center">
        <div className="flex justify-center mt-2 mb-4 w-3/4 bg-white border-black-1 border-rounded rounded-fully p-4">
          <h1 className="uppercase font-bold text-2xl">Trainee registration</h1>
        </div>
      </div>

      <div className="bg-gray-400 flex justify-center items-center  h-screen pt-4">
        <div className="w-1/2 h-screen bg-white border border-black-1 border-rounded rounded-fully p-4 ">
          <form onSubmit={handlesubmit}>
            <div className="grid grid-cols-2">
              <div>
                <label className="font-bold">First Name</label>
                <br />
                <input
                  type="text"
                  onChange={(e) => setFirstname(e.target.value)}
                  className=" h-8 pl-2 border border-gray-300"
                />
              </div>

              <div>
                <label className="font-bold">Middle Name</label>
                <br />
                <input
                  type="text"
                  onChange={(e) => setMiddlename(e.target.value)}
                  className=" h-8 pl-2 border border-gray-300"
                />
              </div>

              <div>
                <label className="font-bold">Last Name</label>
                <br />
                <input
                  type="text"
                  onChange={(e) => setLastname(e.target.value)}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>

              <div>
                <label className="mt-4 font-bold">Birth Place</label>
                <br />
                <input
                  type="text"
                  onChange={(e) => setBirthplace(e.target.value)}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>

              <div>
                <label className="mt-4 font-bold">Sex</label>
                <br />
                <input
                  list="sexlist"
                  type="text"
                  onChange={(e) => setSex(e.target.value)}
                  placeholder="sex"
                  className=" h-8 pl-2 border border-gray-300"
                />
                <datalist className="bg-white" id="sexlist">
                  <option value="male" />
                  <option value="female" />
                  <option value="male disable" />
                  <option value="female disable" />
                  <option value="male pastoral" />
                  <option value="female pastoral" />
                </datalist>
              </div>

              <div>
                <label className="mt-4 font-bold">Age</label>
                <br />
                <input
                  type="number"
                  onChange={(e) => setAge(e.target.value)}
                  className=" h-8 pl-2 border border-gray-300"
                />
              </div>
              <div>
                <label className="mt-4 font-bold">Year at 12th taken</label>
                <br />

                <select
                  id="countries"
                  onChange={(e) => set12thTaken(e.target.value)}
                  className="pl-2 h-8 border border-gray-300 "
                >
                  <option selected>Choose year</option>
                  {yers.map((data, index) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="mt-4 font-bold">national exam score</label>
                <br />
                <input
                  type="number"
                  onChange={(e) => setNationalscore(e.target.value)}
                  className="pl-2 h-8 border border-gray-300 "
                />
              </div>
              <div>
                <label className="mt-4 font-bold">9th transcript</label>
                <br />
                <input
                  type="number"
                  onChange={(e) => setTranscript9(e.target.value)}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>

              <div>
                <label className="mt-4 font-bold ">10th transcript</label>
                <br />
                <input
                  type="number"
                  onChange={(e) => setTranscript10(e.target.value)}
                  className="h-8 pl-2 border border-gray-300 "
                />
              </div>

              <div>
                <label className="mt-4 font-bold">11th transcript</label>
                <br />
                <input
                  type="number"
                  onChange={(e) => setTranscript11(e.target.value)}
                  className=" h-8 pl-2 border border-gray-300"
                />
              </div>

              <div>
                <label className="mt-4 font-bold">12th transcript</label>
                <br />
                <input
                  type="number"
                  onChange={(e) => setTranscript12(e.target.value)}
                  className=" h-8 pl-2 border border-gray-300"
                />
              </div>
              <div>
                <label className="mt-4 font-bold">Occupation</label>
                <br />

                <select
                  id="deplist"
                  onChange={(e) => setDepartment(e.target.value)}
                  placeholder="Department"
                  className=" h-8 pl-2 border border-gray-300"
                >
                  <option selected>Choose Occupation</option>
                  {departments.map((data, index) => (
                    <option value={data.id}>{data.dep_name}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="mt-4 font-bold">Level</label>
                <br />
                <input
                  list="levellist"
                  type="text"
                  onChange={(e) => setLevel(e.target.value)}
                  placeholder="Level"
                  className=" h-8 pl-2 border border-gray-300"
                />
                <datalist className="bg-white" id="levellist">
                  <option value="I" />
                  <option value="II" />
                  <option value="III" />
                  <option value="IV" />
                  <option value="v" />
                </datalist>
              </div>
              <div>
                <label className="font-bold">Trainee Photo</label>
                <br />
                <input
                  placeholder="Student Photo"
                  type="file"
                  onChange={(e) => setSelectedFile1(e.target.files[0])}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>
              <div>
                <label className="font-bold">Referee Name</label>
                <br />
                <input
                  placeholder="Referee Name"
                  type="text"
                  onChange={(e) => setReferee(e.target.value)}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>

              <div>
                <label className="font-bold">Referee Photo</label>
                <br />
                <input
                  placeholder="Referee Photo"
                  type="file"
                  onChange={(e) => setSelectedFile2(e.target.files[0])}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>
              <div>
                <label className="font-bold">Referee Zone</label>
                <br />
                <input
                  placeholder="Referee Zone"
                  type="text"
                  onChange={(e) => setrZone(e.target.value)}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>
              <div>
                <label className="font-bold">Referee Wereda</label>
                <br />
                <input
                  placeholder="Referee Wereda"
                  type="text"
                  onChange={(e) => setrWereda(e.target.value)}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>
              <div>
                <label className="font-bold">Referee Kebele</label>
                <br />
                <input
                  placeholder="Referee Kebele"
                  type="text"
                  onChange={(e) => setrKebele(e.target.value)}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>
              <div>
                <label className="font-bold">Referee Pho.No</label>
                <br />
                <input
                  placeholder="Referee Pho.No"
                  type="number"
                  onChange={(e) => setrPhone(e.target.value)}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>
              <div>
                <label className="mt-4 font-bold">Registration Date</label>
                <br />
                <input
                  type="date"
                  onChange={(e) => setRegDate(e.target.value)}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>
            </div>

            <div className="flex justify-center mt-4">
              <button
                type=""
                className="uppercase bg-red-500 text-white px-4 py-1 rounded rounded-fully"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StudentRegistration;
