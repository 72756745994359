import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContex } from "../../context API/UserContext";

const EnterRecord = () => {
  const [userData, setUserData] = useContext(UserContex);
  const [userName, setUserName] = useState(userData.user?.name);
  const [Level, setLevel] = useState([]);
  const [level, seTlevel] = useState("");
  const [dep, setDep] = useState(null);
  const [course, setCourse] = useState([]);
  const [competency, setCompetency] = useState("");
  const [date, setDate] = useState("");
  const [theory, setTheory] = useState("");
  const [practical, setPractical] = useState("");
  const [industry, setIndustry] = useState("");
  // const [Levels, setLevels] = useState([]);
  // setUserName(userData?.user.name);

  // console.log(userName);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/student/getlevel/" + id)
      .then((res) => {
        setLevel(res.data.data);
        setDep(res.data.data.dep_id);
      })
      .catch((err) => console.log(err));
  }, []);
  // console.log(dep);
  const handlelevel = (e) => {
    e.preventDefault();
    axios
      .post("https://backend.akptc.et/api/course/getCourseByl", { dep, level })
      .then((res) => {
        setCourse(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  console.log(date);
  let grade = "";

  let Record = Number(industry) + Number(theory) + Number(practical);

  if (Record >= 95) {
    grade = "A+";
  } else if (Record >= 92) {
    grade = "A";
  } else if (Record >= 89) {
    grade = "A-";
    console.log(grade);
  } else if (Record >= 86) {
    grade = "B+";
  } else if (Record >= 83) {
    grade = "B";
  } else if (Record >= 80) {
    grade = "B-";
  } else if (Record >= 77) {
    grade = "C+";
  } else if (Record >= 74) {
    grade = "C";
  } else {
    grade = "F";
  }
  console.log(grade);
  console.log(Record);
  const handlesubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://backend.akptc.et/api/record/setsRecord", {
        id,
        userName,
        competency,
        date,
        theory,
        practical,
        industry,
        level,
        grade,
      })
      .then((res) => {
        alert(res.data.msg);
        navigate("/studentRecord");
      })
      .catch((err) => alert(err.response.data.msg));
  };
  let yers = [];
  for (let y = 2008; y <= 2050; y++) {
    yers.push(y);
    // console.log(y);
  }
  let Levels = [];

  if (Level.level === "I") {
    Levels = ["I"];
  } else if (Level.level === "II") {
    Levels = ["I", "II"];
  } else if (Level.level === "III") {
    Levels = ["I", "II", "III"];
  } else if (Level.level === "IV") {
    Levels = ["I", "II", "III", "IV"];
  } else if (Level.level === "V") {
    Levels = ["I", "II", "III", "IV", "V"];
  }
  console.log(level);

  return (
    <div className="bg-gray-400  pb-24 h-full">
      <div className="flex justify-center">
        <div className="flex justify-start mt-2  w-3/4 bg-gray-400 border-none py-4">
          <div className="text-black bg-gray-300 w-52 h-full flex pl-2">
            <form onClick={handlelevel} className="flex ">
              <label for="Level" className="font-bold">
                Level:
              </label>
              <select
                name="level"
                id="competencylist"
                onChange={(e) => seTlevel(e.target.value)}
                placeholder="Level"
                className=" h-8 pl-2 border border-gray-600 bg-gray-400"
              >
                <option selected>Choose Level</option>
                {Levels.map((data, index) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </form>
          </div>
        </div>
      </div>

      <div className="bg-gray-400 flex justify-center mt-12  h-screen ">
        <div className="w-1/2 h-1/2 bg-white border border-black-1 border-rounded rounded-fully p-4 ">
          <form onSubmit={handlesubmit}>
            <div className="grid grid-cols-2">
              <div>
                <label for="competency" className="font-bold">
                  UC:
                </label>
                <br />
                <select
                  name="competency"
                  id="competencylist"
                  onChange={(e) => setCompetency(e.target.value)}
                  placeholder="competency"
                  className=" pl-2 h-8 border w-3/5 border-gray-300 "
                >
                  <option selected>Choose UC</option>
                  {course.map((data, index) => (
                    <option value={data.id}>{data.course_name}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="font-bold">Practical</label>
                <br />
                <input
                  type="number"
                  placeholder="Practical"
                  onChange={(e) => setPractical(e.target.value)}
                  className=" h-8 pl-2 border border-gray-300"
                />
              </div>

              <div>
                <label className="font-bold">Theory</label>
                <br />
                <input
                  type="number"
                  placeholder="Theory"
                  onChange={(e) => setTheory(e.target.value)}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>
              <div>
                <label className="font-bold">Industry Assesment</label>
                <br />
                <input
                  placeholder="Industry Assesment"
                  type="number"
                  onChange={(e) => setIndustry(e.target.value)}
                  className="h-8 pl-2 border border-gray-300"
                />
              </div>
            </div>
            <div>
              <label className="mt-4 font-bold">Date of submission</label>
              <br />
              <input
                type="date"
                onChange={(e) => setDate(e.target.value)}
                className="h-8 pl-2  border border-gray-300"
              />
            </div>

            <div className="flex justify-center mt-4">
              <button
                type=""
                className="uppercase bg-red-500 text-white px-4 py-1 rounded rounded-fully"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnterRecord;
