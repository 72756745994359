import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Aheader from "../Aheader/Aheader";
import { Sidebar1 } from "./Sidebar1";
import logo from "./../../Images/312171893_500382585442367_742616998542970294_n.jpg";
const DisplayRecord = () => {
  const { id } = useParams();
  const [Level, setLevel] = useState(null);
  // const [dep, setDep] = useState(null);
  const [level, seTlevel] = useState("");
  const [first, seTFirst] = useState("");
  const [middle, seTMiddle] = useState("");
  const [last, seTLast] = useState("");
  const [record, setRecord] = useState([]);
  const [dep, setDep] = useState(null);
  const [course, setCourse] = useState([]);
  // const [GPA, setGPA] = useState("");
  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/student/getlevel/" + id)
      .then((res) => {
        setLevel(res.data.data.level);
        seTFirst(res.data.data.first_name);
        seTMiddle(res.data.data.middle_name);
        seTLast(res.data.data.last_name);
        setDep(res.data.data.dep_id);
        console.log(res.data.data.level);
      })
      .catch((err) => console.log(err));
  }, []);
  // console.log(Level);
  let Levels = [];

  if (Level === "I") {
    Levels = ["I"];
  } else if (Level === "II") {
    Levels = ["I", "II"];
  } else if (Level === "III") {
    Levels = ["I", "II", "III"];
  } else if (Level === "IV") {
    Levels = ["I", "II", "III", "IV"];
  } else if (Level === "V") {
    Levels = ["I", "II", "III", "IV", "V"];
  }

  const handlelevel = (e) => {
    e.preventDefault();
    axios
      .post("https://backend.akptc.et/api/record/getsRecord", { id, level })
      .then((res) => {
        setRecord(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => console.log(err));
    axios
      .post("https://backend.akptc.et/api/course/getCourseByl", { dep, level })
      .then((res) => {
        setCourse(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  let g = "-";
  let GP = 0;
  if (record.length < course.length) {
    g = "incomplete";
  } else {
    for (let i = 0; i < record.length; i++) {
      GP += record[i].grade_point;
    }
    g = GP / record.length;
  }
  console.log(g);
  return (
    <div className="m-0 p-0">
      <div className="flex justify-center items-center px-4  bg-black shadow dark:bg-gray-800 h-24 w-full">
        <div className="flex items-center">
          <img
            src={logo}
            alt=""
            className="h-20 w-20 border rounded-full mr-4"
          />
          <h1 className="uppercase text-2xl  font-bold text-white font-serif italic">
            athlete kenenisa Bekele polythecnic college
          </h1>
        </div>
      </div>
      <div className="flex flex-col gap-8 absolute ml-0 mt-24 w-full">
        <div className="flex items-center gap-12">
          <div className="text-black bg-gray-300 w-52 h-full pl-2">
            <form onClick={handlelevel} className="flex ">
              <label for="Level" className="font-bold">
                Level:
              </label>
              <select
                name="level"
                id="competencylist"
                onChange={(e) => seTlevel(e.target.value)}
                placeholder="Level"
                className=" h-8 pl-2 border border-gray-600 bg-gray-400"
              >
                <option selected>Choose Level</option>
                {Levels.map((data, index) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </form>
          </div>
          <h3 className="font-serif text-lg">
            <b>Trainee Name:</b> {" " + first + " " + middle + " " + last}
          </h3>
          <h3 className="font-serif text-lg flex">
            <b>GPA:</b>
            {record ? <p>{g}</p> : null}
          </h3>
        </div>
        <div className="mt-2 px-4 grid grid-cols-1">
          <table className="border-collapse border border-slate-500 w-full ">
            <thead className="font-bold bg-gray-300">
              <tr>
                <td className="border border-slate-700 font-serif px-8">#</td>

                <td className="border border-slate-700 font-serif px-8">UC</td>

                <td className="border border-slate-700 font-serif px-8">
                  Practical
                </td>
                <td className="border border-slate-700 font-serif px-8">
                  Theory
                </td>
                <td className="border border-slate-700 font-serif px-8">
                  Industry <br /> Assesment
                </td>
                <td className="border border-slate-700 font-serif px-8">
                  100%
                </td>
                <td className="border border-slate-700 font-serif px-8">
                  Grade
                </td>
                <td className="border border-slate-700 font-serif px-8">
                  Grade Point
                </td>
                <td className="border border-slate-700 font-serif px-8">
                  Nominal Hour
                </td>
                <td className="border border-slate-700 font-serif px-8">
                  Registrar Name
                </td>
                <td className="border border-slate-700 font-serif px-8">
                  Submission Date
                </td>
              </tr>
            </thead>
            <tbody>
              {record.map((data, index) => (
                <tr>
                  <td className="border border-slate-600">{index + 1}</td>
                  <td className="border border-slate-600">
                    {data.course_name}
                  </td>

                  <td className="border border-slate-600">{data.practical}</td>
                  <td className="border border-slate-600">{data.theory}</td>
                  <td className="border border-slate-600">{data.industry}</td>
                  <td className="border border-slate-600">
                    {data.industry + data.theory + data.practical}
                  </td>
                  <td className="border border-slate-600">{data.grade}</td>
                  <td className="border border-slate-600">
                    {data.grade_point}
                  </td>
                  <td className="border border-slate-600">
                    {data.nominal_hour}
                  </td>
                  <td className="border border-slate-600">{data.user_name}</td>
                  <td className="border border-slate-600">{data.sub_date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DisplayRecord;
