import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import "./../Admin/Sidebar";
export function Sidebar1() {
  return (
    <Card className="h-[calc(100vh-2rem)] w-full max-w-[15rem]  bg-gray-300 shadow-2xl shadow-blue-gray-900/5 gap-12 bgimg fixed mt-20">
      <div className="bg-black opacity-70 p-4  text-white h-screen w-full ">
        <div className="mb-2 p-4 uppercase font-bold">
          <Typography variant="h5" color="blue-gray">
            Registration
          </Typography>
        </div>
        <List className="block font-bold">
          <Link
            to={"/registration"}
            className="hover:text-orange active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 "
          >
            <ListItem>
              <ListItemPrefix>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
              </ListItemPrefix>
              Home
            </ListItem>
          </Link>
          <Link
            to={"/student"}
            className="hover:text-orange active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300"
          >
            <ListItem className="ml-8 py-2">Students</ListItem>
          </Link>
          <Link
            to={"/studentRecord"}
            className="hover:text-orange active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 "
          >
            <ListItem className="ml-8">Student Record</ListItem>
          </Link>
        </List>
      </div>
    </Card>
  );
}
